<template>
  <v-container
    class="fondoLogin d-flex align-center"
    style="height: 100%"
    fluid
  >
    <v-row justify="center">
      <v-col cols="10" sm="4">
        <v-card style="background: #f7f6f4">
          <div class="pt-5 pb-5 d-flex justify-center">
            <v-img
              max-width="250"
              eager
              :src="require('../assets/Click2Call.png')"
            />
          </div>
          <v-form ref="registerForm" v-model="valid" lazy-validation>
            <div class="mt-n3">
              <p
                style="color:#2250b3"
                class="display-2 text-center pt-4 font-weight-regular"
              >
                Registro
              </p>
            </div>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    class="mb-n3"
                    filled
                    rounded
                    single-line
                    v-model="name"
                    :rules="[rules.required]"
                    label="Nombre"
                    maxlength="20"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    class="mb-n3"
                    filled
                    rounded
                    single-line
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    class="mb-n3"
                    filled
                    rounded
                    single-line
                    :rules="[rules.minPhone]"
                    v-model="phone_number"
                    label="Numero Telefonico"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    class="mb-n3"
                    filled
                    rounded
                    single-line
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Contraseña"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    class="mb-n3"
                    filled
                    rounded
                    single-line
                    block
                    v-model="verify"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, passwordMatch]"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    label="Confirmar Contraseña"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                <div>
                  <v-alert
                    class="text-center"
                    color="#2250b3"
                    style="color:white"
                    :value="alert"
                  >
                    Email se encuentra en uso, favor de utilizar otro.
                  </v-alert>
                </div>

                </v-col>
                <v-spacer></v-spacer>
                <v-col class="mx-auto" cols="12" sm="3" xsm="12">
                  <v-btn
                    x-large
                    block
                    rounded
                    :disabled="!disnableBtn"
                    text
                    style="color: #2250b3; border: solid 1px #2250b3"
                    @click="register()"
                    >Registrar</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { register } from "../helpers/api/calls";
export default {
  name: "Login",
  data: () => ({
    alert: false,
    login: false,
    valid: true,
    showMessage: false,
    messageColor: "error",
    show1: false,
    message: "",
    verify: "",

    email: "",
    name: "",
    password: "",
    phone_number: `+56`,
    referral: null,

    showPass: false,
    emailRules: [
      (v) => !!v || "Email es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
    rules: {
      required: (value) => !!value || "Requerido.",
      min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      minPhone: (v) => (v && v.length >= 9) || "Debe contener el codigo del país y Mínimo 9 digitos",
    },
    passwordRules: [(v) => !!v || "Contraseña es requerida"],
  }),

  computed: {
    passwordMatch() {
      return () => (this.password == this.verify)  || "Contraseña debe coincidir";
    },
    disnableBtn() {
      return (
        this.verify != "" &&
        this.email != "" &&
        this.name != "" &&
        this.password != "" &&
        this.phone_number != "" &&
        this.phone_number.length > 9 &&
        this.password === this.verify 
      );
    },
  },
  watch: {
    "$route.query.referral": {
      immediate: true,
      handler(referral) {
        console.log("queReferral: ", referral);
        if (referral) {
          this.referral = referral;
        }
      },
    },
  },
  methods: {
    async register() {
      register({
        name: this.name,
        email: this.email,
        password: this.password,
        referral: this.referral,
        phone_number: this.phone_number,
      })
        .then((resp) => {
          // console.log("resp: ", resp)
          if (resp) {
            this.$router.push({ name: "Home" });
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response && err.response.status == 404) {
            this.alert = true;
            setTimeout(() => {
              this.alert = false;
            }, 6000);
          }
        });
    },
  },
};
</script>

<style scoped>
.fondoLogin {
  background-image: url("../assets/FondoLinea.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
}
</style>
