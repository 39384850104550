<template>
  <v-container
    class="FontAvenir"
    fluid
    :style="
      !$vuetify.breakpoint.mobile
        ? 'background: #f7f7f7;position: relative;margin-top: -14%;transform: skewY(-5deg);'
        : ''
    "
  >
    <v-row
      :class="!$vuetify.breakpoint.mobile ? 'mt-10' : ''"
      :style="
        !$vuetify.breakpoint.mobile
          ? 'background: #f7f7f7; transform: skewY(5deg)'
          : 'background: #f7f7f7;'
      "
    >
      <v-col cols="12" md="5" sm="0">
        <div>
          <v-img
            eager
            :src="require('../../assets/button.png')"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'float:right;top:0;left: 0;right:;max-width: 50%;'
                : 'display:none'
            "
          />
        </div>
      </v-col>
      <v-col md="7"  sm="12" cols="12">
        <div :style="!$vuetify.breakpoint.mobile ? 'width: 55%; margin-top: 10%' : ''">
          <p :class="$vuetify.breakpoint.mobile ? 'display-2 font-weight-medium text-center':'display-2 font-weight-medium'" style="color: #2250b3">
            AGREGA NUESTRO BOTÓN A TU WEB
          </p>
          <hr
            class="ml-10"
            :style="!$vuetify.breakpoint.mobile ? 'width: 25%; border: solid 2px; color: #fec12d' : 'width: 80%; border: solid 2px; color: #fec12d'"
          />
          <p style="color: #2250b3" :class="!$vuetify.breakpoint.mobile ? 'mt-5' : 'mt-10'" >
            Mediante un botón incorporado en la página WEB de la Empresa, los
            clientes se podrán comunicar, instantáneamente por voz, con el Call
            Center o Departamento de Ventas de la misma, tan sólo con hacer un
            clic de mouse sobre dicho botón.
          </p>
        </div>
        <div :class="!$vuetify.breakpoint.mobile ? 'mt-10 mb-10' : 'mt-10 mb-10 text-center'">
          <v-btn
            elevation="6"
            height="60"
            width="200"
            class="rounded-xl"
            style="color: #2250b3"
            ><p class="mt-4 subtitle-2 font-weight-medium">
              Solicitar Prueba
            </p></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AgregaBoton",
};
</script>

<style>
</style>