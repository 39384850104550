<template>
  <div style="background: #f7f7f7" fluid class="pa-0 FontAvenir">
    <v-container
      fluid
      class="FondoVideoMail"
      :style=" !$vuetify.breakpoint.mobile ?
        'position: relative;transform: skewY(-5deg);margin-top: -9.5%;height: 70vh;z-index:4;':''"
    >
      <v-row class="mt-5" :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(5deg)':''">
        <v-col cols="12" sm="6">
          <div style="margin-top: 15%" class="text-center">
            <p style="color: white" class="display-2 font-weight-medium">
              VIDEO MAIL
            </p>
            <hr
              class="mx-auto mt-7"
              :style="!$vuetify.breakpoint.mobile ? 'width: 20%; border: solid 3px; color: #2250b3':'width: 60%; border: solid 3px; color: #2250b3'"
            />
            <p
              :style="!$vuetify.breakpoint.mobile ? 'width: 50%; color: white':'color: white'"
              :class="!$vuetify.breakpoint.mobile ? 'text-start mx-auto mt-10':'mt-10 text-center'"
            >
              Los ejecutivos tendrán la posibilidad de Enviar un mensaje en
              Video a sus clientes para informar sobre alguna promoción, novedad
              o enviarle un saludo con el fin de mantener la fidelización en
              base a un mensaje transparente y amigable a través de un video.
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div>
            <v-img
              eager
              style="margin-top: -10%"
              :src="require('../../assets/Video.png')"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      :class="!$vuetify.breakpoint.mobile ? 'mb-16':''"
      fluid
      style="background: #f7f7f7; margin-top: 10%"
    >
      <v-row>
        <v-col cols="12">
          <p
            :style="!$vuetify.breakpoint.mobile ? 'width: 30%; color: #2250b3':'color: #2250b3'"
            class="display-2 mx-auto text-center font-weight-medium"
          >
            MEDIANTE VIDEO MAIL LA EMPRESA PODRÁ:
          </p>
        </v-col>
      </v-row>
      
      <v-row class="mt-10" justify="center">
        <v-col cols="12" sm="7">
            <div :style="!$vuetify.breakpoint.mobile ? 'display:none':''">
              <img
                eager
                :src="require('../../assets/IconPay.svg')"
                class="mt-n10"
                style="margin-left:27%"
              />
            </div>          
          <v-card height="70" :class="!$vuetify.breakpoint.mobile ? 'rounded-lg d-flex flex-row':'rounded-lg '">
            <div :style="!$vuetify.breakpoint.mobile ? '':'display:none'">
              <img
                eager
                :src="require('../../assets/IconPay.svg')"
                class="mt-n10 ml-n16"
              />
            </div>
            <p :class="!$vuetify.breakpoint.mobile ? 'text-center pt-4 mx-auto':'text-center pt-1'" style="color: #2250b3">
              Entregar el mensaje publicitario a sus contactos de forma amigable
            </p>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-16" justify="center">
        <v-col cols="12" sm="7">
            <div :style="!$vuetify.breakpoint.mobile ? 'display:none':''">
              <img
                eager
                :src="require('../../assets/IconCall.svg')"
                class="mt-n10"
                style="margin-left:27%"
              />
            </div>

          <v-card
            color="#FEB62D"
            height="70"
            class="rounded-lg d-flex flex-row"
          >
            <div :style="!$vuetify.breakpoint.mobile ? '':'display:none'">
              <img
                eager
                :src="require('../../assets/IconCall.svg')"
                class="mt-n10 ml-n16"
              />
            </div>
            <p :class="!$vuetify.breakpoint.mobile ? 'text-center pt-4 mx-auto':'text-center pt-1'" style="color: #2250b3">
              Tener la posibilidad de ser llamada directamente a través de
              Click2Call
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-16 mb-16" justify="center">
        <v-col cols="12" sm="7">
            <div :style="!$vuetify.breakpoint.mobile ? 'display:none':''">
              <img
                eager
                :src="require('../../assets/IconTrade.svg')"
                class="mt-n10"
                style="margin-left:27%"
              />
            </div>             
          <v-card
            color="#2250B3"
            height="70"
            class="rounded-lg d-flex flex-row"
          >
            <div :style="!$vuetify.breakpoint.mobile ? '':'display:none'">
              <img
                eager
                :src="require('../../assets/IconTrade.svg')"
                class="mt-n10 ml-n16"
              />
            </div>
            <p :class="!$vuetify.breakpoint.mobile ? 'text-center pt-4 mx-auto':'text-center pt-1'" style="color: white">
              Mantener la confianza y fidelización y aumentar las ventas
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid :style="!$vuetify.breakpoint.mobile ? 'background: #fec12d; transform: skewY(4deg)':'background: #fec12d;'">
      <v-row class="mb-16" :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(-4deg)':''" justify="center">
        <v-col cols="12" sm="6">
          <div>
            <v-img
              eager
              :class="!$vuetify.breakpoint.mobile ? 'mt-n16':'mx-auto'"
              :max-width="!$vuetify.breakpoint.mobile ? '':'80%'"
              :src="require('../../assets/VideoEmail.png')"
            />
          </div>
          <div :class="!$vuetify.breakpoint.mobile ? 'mt-10 mb-10':''">
            <p style="color: white" class="text-center">
              Los destinatarios verán la promoción u oferta en el Video y en
              caso de interesarse en comprar o contactar al ejecutivo de la
              empresa podrán hacer un clic en el botón Click2Call y elegir las 3
              alternativas de contacto. (Llamada Telefónica, Videollamada o Video Mail).
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "VideoMail",
};
</script>

<style>
.FondoVideoMail {
  background-image: url("../../assets/FondoCallWoman.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
}
</style>