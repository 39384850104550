<template>
  <div style="background: #2250b3" id="SocketVideo">
    <v-container class="pt-0" style="color: black" fluid v-model="VideoLlamada">
      <v-row>
        <v-col sm="12" cols="10">
          <div class="mt-5 ml-7">
            <p
              style="color: #feb62d"
              :class="!$vuetify.breakpoint.mobile ? 'display-1' : 'display-1 '"
            >
              <v-icon
                :class="!$vuetify.breakpoint.mobile ? 'mt-n2' : 'mt-n3 '"
                color="#FEB62D"
                style="font-size: 65px"
                >mdi-video-outline</v-icon
              >
              Video Call
            </p>
          </div>
        </v-col>
      </v-row>

      <div>
        <v-row>
          <v-col
            :class="!$vuetify.breakpoint.mobile ? 'px-0 ' : 'd-none d-sm-flex'"
            sm="4"
            cols="4"
          >
            <v-container style="height: 88.7%; background: #f7f7f7">
              <v-row>
                <v-col>
                  <v-container style="color: #022d41" class="rounded-lg">
                    <div class="d-flex flex-row-reverse">
                      <p
                        style="background: #ddd4d4"
                        class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg"
                      >
                        Nos gustaría coordinar una entrevista de trabajo con
                        usted el día miércoles a las 2 de la tarde<br />
                        <span
                          style="font-size: 10px; float: right"
                          class="blue-grey--text text--lighten-1 Datecontent"
                          >1:59 p.m</span
                        >
                      </p>
                    </div>
                    <div class="d-flex">
                      <p
                        style="background: white"
                        class="pl-2 pr-5 ml-2 pt-2 pb-0 subtitle-1 rounded-lg"
                      >
                        Si Claro<br />
                        <span
                          style="font-size: 10px; float: right"
                          class="
                            blue-grey--text
                            text--lighten-2
                            Datecontent
                            ml-10
                          "
                          >2:00 p.m</span
                        >
                      </p>
                    </div>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>

            <div class="mt-3" style="background: #ffffff">
              <v-row class="pt-4">
                <v-col cols="2">
                  <div class="d-flex flex-nowrap ml-5">
                    <v-file-input class="mt-n2 ml-2" />
                  </div>
                </v-col>

                <v-col class="d-flex flex-row" cols="10">
                  <v-text-field
                    style="max-width: 230px"
                    v-on:keyup.13="sendMessage"
                    filled
                    rounded
                    dense
                    placeholder="Escribe un mensaje aquí"
                    v-model="message"
                  >
                  </v-text-field>
                  <v-btn
                    class="ml-2"
                    rounded
                    height="40"
                    width="40px"
                    style="background: #feb62d"
                    icon
                    :disabled="message === ''"
                  >
                    <v-icon class="ml-1" outlined color="white"
                      >mdi-send</v-icon
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col class="pb-0" sm="8" cols="12">
            <v-row>
              <v-col class="px-0">
                <div
                  class="text-center"
                  id="remoteViewContainer"
                  v-show="remoteVideoSet"
                ></div>
                <div
                  id="remote-waiting"
                  class="d-flex flex-column justify-center align-center"
                  v-if="!remoteVideoSet"
                >
                  <v-progress-circular
                    v-if="esperando == 2"
                    indeterminate
                    color="#2250b3"
                  ></v-progress-circular>
                  <p v-if="esperando == 2" class="mb-0">Esperando conexión</p>
                  <p v-if="esperando == 3" class="mb-0">Fin de la video llamada</p>
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-5 mb-10">
              <v-col cols="12" sm="6">
                <div class="text-end">
                  <v-btn
                    @click="leave()"
                    width="75"
                    height="75"
                    icon
                    :style="
                      !$vuetify.breakpoint.mobile
                        ? 'background-color: #cc2d2d;margin-right: -50px;margin-top:-100px'
                        : 'background-color: #cc2d2d;margin-top: -20px;margin-right:150px'
                    "
                    ><v-icon style="font-size: 40px" color="white"
                      >mdi-phone-hangup</v-icon
                    ></v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div
                  style="width: 400px; height: 300px"
                  :class="
                    !$vuetify.breakpoint.mobile ? 'text-end' : 'text-start'
                  "
                  :style="
                    !$vuetify.breakpoint.mobile ? ' ' : 'margin-top: -69%'
                  "
                  id="localViewContainerr"
                  v-show="localVideoSet"
                ></div>

                <div
                  style="background-color: white; width: 400px; height: 300px"
                  :class="
                    !$vuetify.breakpoint.mobile
                      ? 'text-end d-flex flex-column justify-center align-center'
                      : 'text-start'
                  "
                  :style="
                    !$vuetify.breakpoint.mobile ? ' ' : 'margin-top: -69%'
                  "
                  id="remote-waiting-local"
                  v-if="!localVideoSet"
                >
                  <div class="text-center">
                    <v-progress-circular
                      indeterminate
                      color="#2250b3"
                    ></v-progress-circular>
                    <p class="mb-0 mt-5">Esperando conexión</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import io from "socket.io-client";
export default {
  name: "SocketVideo",
  //   props:{
  //     volver:{
  //       type:Function
  //     }
  //   },
  data() {
    return {
      esperando: 2,

      VideoLlamada: false,
      dialog: null,
      socket: null,
      pcPeers: [],
      remoteStream: null,
      localStream: null,
      close: null,
      idSala: null,
      remoteVideoSet: false,
      localVideoSet: false,

      message: "",
    };
  },
  mounted() {
    const { sala_id } = this.$route.params;
    console.log("testing",sala_id);

    if (sala_id) {
      this.socket = io("https://vctester.naturalphone.cl:16443/", {
        transports: ["websocket"],
        autoConnect: false,
        reconnect: true,
        forceNew: true,
      });
      this.startVideollamada(sala_id);
    }

    this.volver;
  },
  methods: {
    volvermenu() {
      router.push({ path: "panel" });
    },
    startVideollamada(sala_id) {
      const self = this;
      // this.dialog = true;
      // self.cerrar();
      console.log("startVideollamada");
      this.socket.on("connect", function () {
        console.log("Connect: ", sala_id);
        // Esto se llama cuando te conectas al socket
        // Luego llamas al join para unirte a una sala
        // Esta sala es la que comentamos que iba a venir desde el backend para que en la app y en la web sea la misma
        // Ahora para efectos de prueba la sala es 123
        // self.join( self.$store.state.modalVideollamada.meeting_room )
        self.join(sala_id);
      });
      this.socket.on("exchange", function (data) {
        self.exchange(data);
      });
      this.socket.on("leave", function (socketId) {
        self.remoteVideoSet = false,
        self.esperando = 3,
        console.log("socketId", socketId);
        //Manejo de eventos del disconnect
        
        

        
      });
      // Conectamos al socket apenas se monta el componente, esto lo puedes manejar de otra forma o, si te sirve, así como está
      // También en esta función se pide el video del usuario si es que no lo ha pedido
      this.connectToSocket();
    },

    async connectToSocket() {
      console.log("connectToSocket");
      if (!this.localStream) {
        this.localStream = await this.getUserMedia(true, true);
      }
      this.setVideo();
      console.log("setVideo 2: ", this.socket);
      this.socket.connect();
    },
    getUserMedia(video = true, audio = true) {
      return new Promise(function (resolve, reject) {
        let setTimeoutMedia = setTimeout(function () {
          reject();
        }, 40000);
				console.log("navigator: ", navigator)
        navigator.getUserMedia =
					navigator.mediaDevices.getUserMedia ||
          navigator.getUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.msGetUserMedia;

				console.log("navigator.getUserMedia: ", navigator.getUserMedia)

        let videoOptions = false;
        if (video) {
          videoOptions = {
            width: { min: 426, ideal: 480, max: 1280 },
            height: { min: 240, ideal: 360, max: 720 },
            frameRate: { ideal: 15, max: 30 },
          };
        }

        navigator.getUserMedia(
          {
            video: videoOptions,
            audio,
          },
          function (localMediaStream) {
            clearTimeout(setTimeoutMedia);
            resolve(localMediaStream);
          },
          function (err) {
            alert(
              "El navegador no ha permitido el uso de la cámara y/o micrófono. O es posible que el computador no tenga una cámara conectada. Por favor revisa la configuración de tu navegador para luego continuar con el monitoreo."
            );
            console.log("Ocurrió el siguiente error: " + err);
          }
        );
      });
    },
    setVideo(remote = false) {
      console.log("setVideo");
      if (remote) {
        var ViewAuxRemote = document.getElementById("remoteViewContainer");
        var remoteVideo = document.createElement("video");
        remoteVideo.id = "remote-view";
        remoteVideo.srcObject = this.remoteStream;
        remoteVideo.autoplay = "autoplay";
        ViewAuxRemote.appendChild(remoteVideo);
        this.remoteVideoSet = true;
      } else {
        var ViewAuxLocal = document.getElementById("localViewContainerr");
        var localVideo = document.createElement("video");
        localVideo.id = "local-view";
        localVideo.muted = "muted";
        localVideo.srcObject = this.localStream;
        localVideo.autoplay = "autoplay";
        localVideo.muted = "muted";
        ViewAuxLocal.appendChild(localVideo);
        this.localVideoSet = true;
      }
    },

    join(roomID) {
      const self = this;
      console.log("JOIN1");
      this.socket.emit("join", roomID, function (socketIds) {
        console.log("join", socketIds);
        for (var i in socketIds) {
          var socketID = socketIds[i];
          // Al hacer join te muestra los id de los usuarios que ya están conectados al socket
          // Luego se llama a la función que crea los peer connections
          // Nos aseguramos que no se llame a si mismo con el if
          console.log("JOIN");
          if (socketID != self.socket.id) {
            self.createPC(socketID, true);
          }
        }
      });
    },

    leave() {
      const self = this;
      this.dialog = false;
      //Manejo de evento LocalSet y RemoteSet
      this.localVideoSet = false;
      this.remoteVideoSet = false;
      
      //Al desconectar traslado a la persona al panel con un router.push
      this.$router.push({ name: "panel", });

      return new Promise(function () {
        self.pcPeers.forEach((pc) => {
          if (pc) {
            pc.close();
          }
        });
        let video = document.getElementById("remote-view");
        video && video.remove();

        let videoPIP = document.getElementById("monitor-remote-pip-view");
        videoPIP && videoPIP.remove();
        if (self.localStream) {
          self.localStream.getAudioTracks().forEach((track) => {
            track.enabled = false;
          });
          self.localStream.getVideoTracks().forEach((track) => {
            track.enabled = false;
          });
          self.localStream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
        self.socket.disconnect();
        self.remoteStream = null;
        self.localStream = null;
        let videoLocal = document.getElementById("local-view");
        videoLocal && videoLocal.remove();
        
      });
    },

    exchange(data) {
      var fromId = data.from;
      var pc;
      const self = this;

      if (fromId in self.pcPeers) {
        pc = self.pcPeers[fromId];
      } else {
        pc = self.createPC(fromId, false);
      }

      if (data.sdp) {
        pc.setRemoteDescription(new RTCSessionDescription(data.sdp))
          .then(() => {
            if (pc.remoteDescription.type === "offer")
              pc.createAnswer()
                .then((desc) => {
                  pc.setLocalDescription(desc)
                    .then(() => {
                      self.socket.emit("exchange", {
                        to: fromId,
                        sdp: pc.localDescription,
                      });
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                })
                .catch(function (error) {
                  console.log(error);
                });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        data.candidate !== null &&
          data.candidate &&
          pc.addIceCandidate(new RTCIceCandidate(data.candidate));
      }
    },

    createPC(socketId, isOffer) {
      const self = this;
      var configuration = {
        iceServers: [
          {
            urls: "turn:18.220.229.28:3478",
            username: "sosclickturn",
            credential: "turnsosclick2021",
          },
        ],
      };
      var pc = new RTCPeerConnection(configuration);
      self.pcPeers[socketId] = pc;

      pc.onicecandidate = function (event) {
        if (event.candidate && event !== null && event.candidate !== null) {
          self.socket.emit("exchange", {
            to: socketId,
            candidate: event.candidate,
          });
        }
      };

      // function createOffer() {
      //     pc.createOffer(function(desc) {
      //         pc.setLocalDescription(desc,function () {
      //             self.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription });
      //         },(error) => console.log(error,'error 2'));
      //     },(error) => console.log(error,'error 3'));
      // }

      async function createOffer() {
				const desc = await pc.createOffer()
				await pc.setLocalDescription(desc)
				self.socket.emit("exchange", {
					to: socketId,
					sdp: pc.localDescription,
				});
        // pc.createOffer().then((desc) => {
        //   console.log("createOffer", desc);
        //   pc.setLocalDescription(desc).then(() => {
        //     console.log("setLocalDescription", pc.localDescription);
        //     self.socket.emit("exchange", {
        //       to: socketId,
        //       sdp: pc.localDescription,
        //     });
        //   });
        // });
      }

      pc.onnegotiationneeded = function () {
        if (isOffer) {
          createOffer();
        }
      };

      pc.oniceconnectionstatechange = function (event) {
        if (event.target.iceConnectionState === "connected") {
          createDataChannel();
        }
      };

      pc.onsignalingstatechange = function (event) {
        if (event.target.connectionState === "disconnected") {
          self.leave();
        }
      };

      pc.onaddstream = function (event) {
        self.remoteStream = event.stream;
        self.setVideo(true);
      };

      pc.addStream(self.localStream);

      function createDataChannel() {
        if (pc.textDataChannel) {
          return;
        }
        var dataChannel = pc.createDataChannel("text");
        dataChannel.onerror = function (error) {
          console.log(error);
        };
        dataChannel.onmessage = function (event) {
          console.log(event);
        };
        dataChannel.onopen = function () {};
        dataChannel.onclose = function () {};

        pc.textDataChannel = dataChannel;
      }

      return pc;
    },
  },
};
</script>

<style>
</style>