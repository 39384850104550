<template>
  <v-dialog
    v-model="$store.state.modalVideoState"
    :max-width="dialogWidth"
    :scrollable="false"
    persistent
  >
    <v-card class="pa-0" :height="dialogHeight + 'px'" style="overflow-y: hidden;">
      <v-card-text class="pa-0">
				<video-player
					v-if="loadPlayers"
					class="home-video-background-top"
					ref="videoPlayer"
					:playsinline="true"
					:options="playerOptions"
				>
				</video-player>
      </v-card-text>
      <v-btn
        icon
        color="#2250b3"
        @click="closeModal()"
        style="position: absolute; top: 20px; right: 20px;"
      >
        <v-icon x-large>mdi-close-circle</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
	import "video.js/dist/video-js.css";
	import { videoPlayer } from "vue-video-player";

  export default {
    name: 'VideoModal',
		components: { videoPlayer },
    data: () => ({
				playerOptions: null,
				playerOptionsB: null,
				loadPlayers: false,
        vid: false,
        dialogWidth: 200,
        dialogHeight: 200
    }),
    mounted() {
      this.setSizes()
    },
    methods: {
      setSizes(){
        this.dialogHeight = window.innerHeight * 0.85
        this.dialogWidth = window.innerWidth * 0.7
				this.playerOptions = {
					// videojs options
					autoplay: true,
					muted: false,
					loop: true,
					fluid: true,
					aspectRatio: Math.round(this.dialogWidth) + ":" + Math.round(this.dialogHeight),
					controls: false,
					language: "es",
					sources: [
						{
							type: "video/mp4",
							src: "/click2call.mp4",
						},
					],
				}
        window.onresize = this.setSizes 
    		this.loadPlayers = true
      },
      playerReady(event){
        this.player = event.target
      },
      closeModal(){
        this.$store.commit('setModalVideoState', false)
      }
    },
  }
</script>