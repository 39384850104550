<template>
  <v-container
    class="fondoLogin d-flex align-center justify-center"
    style="height: 100%"
    fluid
  >
			<v-img class="img-top-background" eager :src="require('@/assets/Fondo.svg')" />
			<v-card class="rounded-xl"
				:class="$vuetify.breakpoint.mobile ? 'pa-2':'pa-10'"
				:min-width="$vuetify.breakpoint.mobile ? '100px':'700px'"
				min-height="350px">
				<div class="pb-5 d-flex justify-center">
					<v-img
						max-width="250"
						eager
						:src="require('@/assets/Click2Call.png')"
					/>
				</div>
				<div class="text-center" v-if="loading">
					<div class="text-center" v-if="stepData">
						<p class="mb-0 text-center" style="color:#2250B3">{{stepData.entry_form_description}}</p>
						<div v-html="stepData.entry_form_text"></div>
						<v-img
							max-width="350"
							height="250"
							contain
							class="mx-auto"
							eager
							v-if="stepData.entry_form_image"
							:src="stepData.entry_form_image"
						/>
					</div>
					<div v-else>
						<v-skeleton-loader
							type="list-item-two-line, image"
						></v-skeleton-loader>
					</div>
				</div>
				<div v-else>
					<div v-if="confirmation_status">
						<h2 class="text-center" style="color:#2250B3">Genial!</h2>
						<p class="text-center" style="color:#2250B3">{{stepData.output_form_description}}</p>
						<v-img
							max-width="350"
							height="250"
							contain
							class="mx-auto"
							eager
							v-if="stepData.output_form_image"
							:src="stepData.output_form_image"
						/>
					</div>
					<div v-else>
						<h2 class="text-center" style="color:#2250B3">Error</h2>
						<p class="text-center mb-0 caption" style="color:#2250B3">Ha ocurrido un error, disculpa las molestias.</p>
						<p class="text-center caption" style="color:#2250B3">Puedes ingresar nuevamente con el link de tu correo.</p>
						<v-img
							max-width="350"
							height="250"
							contain
							class="mx-auto"
							eager
							:src="require('@/assets/error-sales.gif')"
						/>
					</div>
				</div>
			</v-card>
  </v-container>
</template>

<script>
import {confirmProspect, getCurrentStep} from '@/helpers/api/sales'

export default {
  name: "ProspectAccept",
  data: () => ({
		loading: true,
		prospect_id: null,
		step: 1,
		stepData: null,
		next_step: null,
		confirmation_status: false
  }),
	mounted(){
		this.prospect_id = this.$route.query.pc
		this.step = this.$route.query.sp
		this.getStepData()
		this.sendData()
	},
  methods: {
		async getStepData(){
			const step = await getCurrentStep({step_id: this.step})
			if(step.success){
				this.stepData = step.data
			}
		},
		async sendData(){
			if(this.prospect_id){
				this.loading = true
				const confirmation = await confirmProspect({prospect_id: this.prospect_id, step_id: this.step})
				setTimeout(() => {
					this.loading = false
					if(confirmation.success){
						this.next_step = confirmation.data.next_step
						this.confirmation_status = true
					}
				}, 1500);
			}
		}
  },
};
</script>

<style scoped>
.fondoLogin {
  background: linear-gradient(#ffd255,#ffd255);
  background-position: center;
  background-size: cover;
  height: auto;
}
.sol{
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 0;
  left: 0;
}
.img-top-background{
  position: absolute;
  overflow: hidden;
	top: 0;
	right: 0;
  bottom: 0;
  left: 0;
}
.alert-message-container{
	position: absolute;
  z-index: 99;
	top: 50px;
	right: 10px;
}
</style>