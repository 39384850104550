<template>
  <v-container fluid style="background:#F7F6F4;position:relative;z-index:2">
    <v-row>
      <v-col :class="!$vuetify.breakpoint.mobile ? 'asistencia-container':''" cols="12" sm="5">

      </v-col>
      <v-col class="mx-auto" cols="12" md="7" sm="12">
          <div class="text-start">
              <p :class="!$vuetify.breakpoint.mobile ? 'display-1 mt-16':'display-1'" style="color:#2250B3;">LA REVOLUCIÓN DE LA ATENCIÓN AL CLIENTE</p>
              <hr  :style="!$vuetify.breakpoint.mobile ? 'width:12%;border:solid 2px;color:#FEC12D':'width:92%;border:solid 2px;color:#FEC12D'">
          </div>
          <div class="FontAvenir" style="color:#2250B3;">
              <p class=" mt-10">80% de los adultos compra algo en línea cada 6 meses.</p>
              <p >60% de las personas abandonan el carro de compras.</p>
              <p >67% de las personas dejan el sitio WEB debido a la falta de asistencia.</p>
              <p >La aceptación por el E-Commerce cada día crece más pero sigue existiendo un porcentaje de personas que desconfían de la compra on line.</p>
              <p >En Click2Call te ayudamos a mejorar esa confianza y tus Ventas!</p>
          </div>

          <v-btn
            x-large
            outlined
            rounded
            :block="$vuetify.breakpoint.mobile"
            color="#2250b3"
            class="px-6 py-2 my-5"
						@click="$store.commit('setModalVideoState', true)"
          >
            <p class="mb-0 mr-3">Ver Video</p>
            <v-icon>mdi-play</v-icon>
          </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:"RevolucionAtencion",
};
</script>

<style>
.asistencia-container{
	background-image: url('../../assets/Support.png');
	background-position: top left;
	background-size: cover;
	
}
</style>