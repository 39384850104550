<template>
  <div>
    <!-- <Home /> -->
    <Home2 />
    <RevolucionAtencion />
    <Contacto />
    <FormasContacto />
    <AgregaBoton />
    <AtencionPersonalizada />
    <VideoMail />
    <NuestraHerramienta />
    <Drawer />
  </div>
</template>

<script>
import Contacto from "./Contacto.vue";
import Home from "./Home.vue";
import RevolucionAtencion from "./RevolucionAtencion.vue";
import FormasContacto from "./FormasContacto.vue";
import AgregaBoton from "./AgregaBoton.vue";
import AtencionPersonalizada from "./AtencionPersonalizada.vue";
import VideoMail from "./VideoMail.vue";
import NuestraHerramienta from "./NuestraHerramienta.vue";
import Home2 from "./Home2.vue";
import Drawer from "../../drawer/Drawer.vue";
export default {
  name: "Index",
  components: {
    Home,
    RevolucionAtencion,
    Contacto,
    FormasContacto,
    AgregaBoton,
    AtencionPersonalizada,
    VideoMail,
    NuestraHerramienta,
    Home2,
    Drawer
},
  data() {
    return {};
  },
  methods: {
 
  },
};
</script>

<style>
</style>