var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:!_vm.$vuetify.breakpoint.mobile ? 'px-0 FontAvenir':' FontAvenir',staticStyle:{"background":"#f7f7f7"},attrs:{"fluid":""}},[_c('v-row',{class:!_vm.$vuetify.breakpoint.mobile
        ? 'mb-10 mt-10 d-flex flex-nowrap'
        : 'mb-10 mt-10'},[_c('v-col',{staticStyle:{"margin-top":"6%"},attrs:{"cols":"12","sm":"6"}},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'mx-auto pl-10' : 'text-center',style:(!_vm.$vuetify.breakpoint.mobile ? 'width: 60%;float:right' : '')},[_c('p',{staticClass:"display-2 font-weight-medium",staticStyle:{"color":"#2250b3"}},[_vm._v(" FORMAS ACTUALES DE CONTACTO ")]),_c('hr',{class:!_vm.$vuetify.breakpoint.mobile ? '' : 'mx-auto',style:(!_vm.$vuetify.breakpoint.mobile
              ? 'width: 35%; border: solid 2px; color: #fec12d'
              : 'width: 60%; border: solid 2px; color: #fec12d')})]),_c('div',{class:!_vm.$vuetify.breakpoint.mobile
            ? 'mx-auto mt-10 pl-11'
            : 'text-center mt-10',style:(!_vm.$vuetify.breakpoint.mobile ? 'width: 60%; float: right' : '')},[_c('p',{staticStyle:{"color":"#2250b3"}},[_vm._v(" Tomamos la inmediatez y la atención personalizada de una llamada telefónica y la combinamos con la facilidad e instantaneidad de Internet. Además, bajamos el costo del número 800. ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('v-img',{staticClass:"mx-auto ml-1",attrs:{"eager":"","src":require('../../assets/LineaClick.png'),"width":!_vm.$vuetify.breakpoint.mobile ? '50%' : '95%'}})],1)])],1),_c('v-container',{class:!_vm.$vuetify.breakpoint.mobile ? 'probando FondoWoman mb-16' : '',style:(!_vm.$vuetify.breakpoint.mobile ? '' : 'background: #fec12d;'),attrs:{"fluid":""}},[_c('div',{style:(!_vm.$vuetify.breakpoint.mobile ? 'transform: skewY(5deg)' : '')},[_c('v-img',{staticClass:"mb-n3",style:(!_vm.$vuetify.breakpoint.mobile
            ? ' margin-top:-2%;top:0left: 0;right:;max-width: 40%;transform: skewY(-12deg)'
            : 'margin-top:-12%'),attrs:{"eager":"","src":require('../../assets/callwoman.png')}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }