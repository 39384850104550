<template>
  <div class="px-0 FontAvenir" fluid style="background: #f7f7f7">
    <v-container :class="!$vuetify.breakpoint.mobile ? 'px-0 pb-0':'' " fluid :style="!$vuetify.breakpoint.mobile ? 'background: #2250b3; transform: skewY(-5deg)' : 'background: #2250b3;'">
      <v-row :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(5deg)' : ''" class="mt-10">
        <v-col cols="12">
          <v-container>
            <p
            :class="!$vuetify.breakpoint.mobile ? 'display-2 font-weight-medium mx-auto' : 'display-1 font-weight-medium text-center'"
            :style="!$vuetify.breakpoint.mobile ? 'color: white; width: 50%':'color: white;'"
            >
              LOS CLIENTES OBTENDRÁN ATENCIÓN PERSONALIZADA A TRAVÉS DE 3 VÍAS
            </p>
            <hr
              class="mx-auto mt-10"
              :style="!$vuetify.breakpoint.mobile ? 'width: 20%; border: solid 3px; color: #feb62d':'width: 80%; border: solid 3px; color: #feb62d'"
            />
          </v-container>
        </v-col>
      </v-row>

      <v-row :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(5deg)':''" :class="!$vuetify.breakpoint.mobile ? 'mb-16 ':''">
        <v-col  cols="12">
          <div :class="!$vuetify.breakpoint.mobile ? 'd-flex flex-row ml-10 justify-center':''">

            <v-icon :style="!$vuetify.breakpoint.mobile ? 'font-size: 50px':'font-size: 60px; margin-left:43%'" color="#FEB62D"
              >mdi-phone-forward</v-icon
            >
            <p
              style="color: #feb62d"
              :class="!$vuetify.breakpoint.mobile ? 'display-1 mt-2 ml-5 font-weight-medium':'display-1 mt-2 ml-5 font-weight-medium text-center'"
            >
              Llamada Telefónica
            </p>
            <p
              :style="!$vuetify.breakpoint.mobile ? 'color: #feb62d':'display:none'"
              class="title ml-5 mt-2 font-weight-medium"
            >
              ............................
            </p>
            <p style="color: white" :class="!$vuetify.breakpoint.mobile ? 'ml-5 mt-2':'ml-5 mt-2 text-center'">
              Llamado Telefónico inmediato desde la WEB al Ejecutivo.
            </p>
          </div>

          <div :class="!$vuetify.breakpoint.mobile ? 'd-flex flex-row mt-5 ml-n16 justify-center':'mt-10'">
            <v-icon :style="!$vuetify.breakpoint.mobile ? 'font-size: 50px':'font-size: 60px; margin-left:43%'" color="#FEB62D"
              >mdi-cursor-default-click</v-icon
            >
            <p
              style="color: #feb62d"
              :class="!$vuetify.breakpoint.mobile ? 'display-1 mt-2 ml-5 font-weight-medium':'display-1 mt-2 ml-5 font-weight-medium text-center'"
            >
              Videollamada
            </p>
            <p
              :style="!$vuetify.breakpoint.mobile ? 'color: #feb62d':'display:none'"
              class="title ml-5 mt-2 font-weight-medium"
            >
              .....................
            </p>
            <p style="color: white" :class="!$vuetify.breakpoint.mobile ? 'ml-5 mt-2':'ml-5 mt-2 text-center'">
              Para llamado inmediato por video conferencia.
            </p>
          </div>

          <div :class="!$vuetify.breakpoint.mobile ? 'd-flex flex-row  mt-5 justify-center':'mt-10'">
            <v-icon :style="!$vuetify.breakpoint.mobile ? 'font-size: 50px':'font-size: 60px; margin-left:43%'" color="#FEB62D"
              >mdi-video-outline</v-icon
            >
            <p
              style="color: #feb62d"
              :class="!$vuetify.breakpoint.mobile ? 'display-1 mt-2 ml-5 font-weight-medium':'display-1 mt-2 ml-5 font-weight-medium text-center'"
            >
              Video Mail
            </p>
            <p
              :style="!$vuetify.breakpoint.mobile ? 'color: #feb62d':'display:none'"
              class="title ml-3 mt-2 font-weight-medium"
            >
              .......
            </p>
            <p style="color: white" :class="!$vuetify.breakpoint.mobile ? 'ml-5 mt-2':'ml-5 mt-2 text-center'">
              Para enviar un Video Mail al Ejecutivo para ser contactado
            </p>
          </div>
        </v-col>
      </v-row>
      
      <v-container
        class="py-0 pa-0"
        fluid
        :style="!$vuetify.breakpoint.mobile ? 'background: #2250b3':'display:none'"
        
      >
        <v-container fluid :style="!$vuetify.breakpoint.mobile ? 'background: white;transform: skewY(10deg)':''">
          <v-row>
            <v-col>
              <div class="mb-n16">
                <v-img
                  :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(-10deg);':''"
                  :src="require('../../assets/Group.png')"
                  max-height="700"
                  eager
                  

                />
              </div>
              
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AtencionPersonalizada",
};
</script>

<style>
</style>