var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FondoHome",style:(!_vm.$vuetify.breakpoint.mobile ? '' : '')},[_c('v-container',{attrs:{"align-center":"","fill-height":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('div',[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require('../../assets/MinClick2.png'),"eager":"","max-width":"200"}})],1)]),_c('v-col',{class:!_vm.$vuetify.breakpoint.mobile
              ? 'd-flex align-center justify-end'
              : 'mt-n5',attrs:{"sm":"12","md":"9","cols":"12"}},[_c('v-row',{class:!_vm.$vuetify.breakpoint.mobile
                ? 'text-center justify-end'
                : 'text-center'},[_c('v-col',{attrs:{"md":"2","cols":"6","sm":"3"}},[_c('v-btn',{class:!_vm.$vuetify.breakpoint.mobile ? '' : '',attrs:{"color":"#2250B3","text":""}},[_vm._v("Servicios")])],1),_c('v-col',{attrs:{"md":"2","cols":"6","sm":"3"}},[_c('v-btn',{class:!_vm.$vuetify.breakpoint.mobile ? '' : '',attrs:{"color":"#2250B3","text":""}},[_vm._v("Precios")])],1),_c('v-col',{attrs:{"md":"2","cols":"6","sm":"3"}},[_c('v-btn',{class:!_vm.$vuetify.breakpoint.mobile ? '' : '',attrs:{"color":"#2250B3","text":""}},[_vm._v("Contactos")])],1),_c('v-col',{attrs:{"md":"2","cols":"6","sm":"3"}},[_c('v-btn',{class:!_vm.$vuetify.breakpoint.mobile ? ' rounded-xl' : 'rounded-xl',style:(!_vm.$vuetify.breakpoint.mobile
                    ? 'background: white'
                    : 'background: white;'),attrs:{"href":"https://panel.click2call.cl","target":"_blank","color":"#2250B3","text":""}},[_vm._v("Ingresar")])],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"sm":"12","md":"6","xl":"6","lg":"6","cols":"12"}},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? '' : 'd-flex justify-center mr-16',staticStyle:{"position":"relative"}},[_c('video-player',{ref:"videoPlayer",staticClass:"home-video-background-top",class:_vm.$vuetify.breakpoint.mobile ? 'mt-4':'',staticStyle:{"width":"100%","z-index":"9"},attrs:{"playsinline":true,"options":_vm.playerOptions}})],1)]),_c('v-col',{attrs:{"sm":"12","md":"6","xl":"6","lg":"6","cols":"12"}},[_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'mx-auto ' : 'ml-16',style:(!_vm.$vuetify.breakpoint.mobile
                  ? 'background: #F7F6F4;border-radius: 100%;width: 550px;height: 550px;'
                  : 'position:relative;background: #F7F6F4;border-radius: 100%;width: 330px;height: 330px;')},[_c('v-img',{style:(!_vm.$vuetify.breakpoint.mobile
                    ? 'position: absolute;max-width: 745px;margin-top: 99px;right: 10px;'
                    : 'position: absolute;max-width: 330px;top:60px;'),attrs:{"eager":"","src":require('../../assets/Woman.png')}}),_c('v-img',{style:(!_vm.$vuetify.breakpoint.mobile
                    ? 'position: absolute;max-width: 600px;right:100px; top:100px'
                    : 'position: absolute;max-width: 330px;top:35px;right:25px'),attrs:{"eager":"","src":require('../../assets/VideoLlamada.png')}})],1)])],1)],1)],1),_c('div',[_c('v-img',{style:(!_vm.$vuetify.breakpoint.mobile
            ? 'position: absolute;top: 130px;width: 900px;left: -300px;z-index: 1;'
            : 'display:none'),attrs:{"eager":"","src":require('../../assets/Eclipse1.svg')}})],1),_c('Drawer',{style:(!_vm.$vuetify.breakpoint.mobile ? '' : '')})],1)}
var staticRenderFns = []

export { render, staticRenderFns }