
<template>
  <div class="FondoHome" :style="!$vuetify.breakpoint.mobile ? '' : ''">
    <v-container align-center fill-height>
      <v-row>
        <v-col cols="12" sm="3" md="3">
          <div>
            <v-img
              class="mx-auto"
              :src="require('../../assets/MinClick2.png')"
              eager
              max-width="200"
            />
          </div>
        </v-col>

        <v-col
          sm="12"
          md="9"
          cols="12"
          :class="
            !$vuetify.breakpoint.mobile
              ? 'd-flex align-center justify-end'
              : 'mt-n5'
          "
        >
          <v-row
            :class="
              !$vuetify.breakpoint.mobile
                ? 'text-center justify-end'
                : 'text-center'"
            
          >
            <v-col md="2" cols="6" sm="3">
              <v-btn :class="!$vuetify.breakpoint.mobile ? '' : ''" color="#2250B3" text>Servicios</v-btn>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <v-btn color="#2250B3" text :class="!$vuetify.breakpoint.mobile ? '' : ''">Precios</v-btn>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <v-btn color="#2250B3" text :class="!$vuetify.breakpoint.mobile ? '' : ''">Contactos</v-btn>
            </v-col>
            <v-col md="2" cols="6" sm="3">
              <v-btn
                href="https://panel.click2call.cl"
								target="_blank"
                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'background: white'
                    : 'background: white;'
                "
                color="#2250B3"
                text
                :class="
                  !$vuetify.breakpoint.mobile ? ' rounded-xl' : 'rounded-xl'
                "
              >Ingresar</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-container fluid>
        <v-row class="align-center">
          <v-col sm="12" md="6" xl="6" lg="6" cols="12">
            <div
              :class="$vuetify.breakpoint.mobile ? '' : 'd-flex justify-center mr-16'"
              style="position:relative"
            >
              <!-- <v-img
                eager
                :src="require('../../assets/Click2CallM.svg')"

                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'position:absolute;z-index:2;top:200px;margin-bottom: 5%;'
                    : 'position:absolute;z-index:10;left: 0;max-width: 90%;left:20px;top:90px'
                     &&
                    $vuetify.breakpoint.sm ? 'max-width:100%':''
                "
              /> -->
							<video-player
								class="home-video-background-top"
								:class="$vuetify.breakpoint.mobile ? 'mt-4':''"
								ref="videoPlayer"
								:playsinline="true"
								:options="playerOptions"
								style="width: 100%;z-index: 9"
							>
							</video-player>
            </div>
          </v-col>

          <v-col sm="12" md="6" xl="6" lg="6" cols="12">
            <div
              :class="$vuetify.breakpoint.mobile ? 'mx-auto ' : 'ml-16'"
              :style="
                !$vuetify.breakpoint.mobile
                  ? 'background: #F7F6F4;border-radius: 100%;width: 550px;height: 550px;'
                  : 'position:relative;background: #F7F6F4;border-radius: 100%;width: 330px;height: 330px;' 
              "
            >
              <v-img
                eager
                :src="require('../../assets/Woman.png')"
                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'position: absolute;max-width: 745px;margin-top: 99px;right: 10px;'
                    : 'position: absolute;max-width: 330px;top:60px;'
                "
              />
              <v-img
                eager
                :src="require('../../assets/VideoLlamada.png')"
                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'position: absolute;max-width: 600px;right:100px; top:100px'
                    : 'position: absolute;max-width: 330px;top:35px;right:25px'
                "
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <div>
      <v-img
        eager
        :src="require('../../assets/Eclipse1.svg')"
        :style="
          !$vuetify.breakpoint.mobile
            ? 'position: absolute;top: 130px;width: 900px;left: -300px;z-index: 1;'
            : 'display:none'
        "
      />
    </div>
    <Drawer :style="!$vuetify.breakpoint.mobile ? '' : ''" />
  </div>
</template>

<script>
import Drawer from "../../drawer/Drawer.vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "Home",
  components: {
    Drawer,
		videoPlayer
  },
  data() {
    return {
      prueba: [],
			playerOptions: {
				// videojs options
				autoplay: false,
				muted: false,
				loop: true,
				fluid: true,
				// aspectRatio: Math.round(this.dialogWidth) + ":" + Math.round(this.dialogHeight),
				controls: true,
				language: "es",
				sources: [
					{
						type: "video/mp4",
						src: "/click2call.mp4",
					},
				],
			}
    };
  },
};
</script>

<style scoped>
.FondoHome {
  background-image: url("../../assets/FondoMain.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
}

</style>