<template>
  <v-container :class="!$vuetify.breakpoint.mobile ? 'px-0 FontAvenir':' FontAvenir'" fluid style="background: #f7f7f7">
    <v-row
      :class="
        !$vuetify.breakpoint.mobile
          ? 'mb-10 mt-10 d-flex flex-nowrap'
          : 'mb-10 mt-10'
      "
    >
      <v-col style="margin-top: 6%" cols="12" sm="6">
        <div
          :style="!$vuetify.breakpoint.mobile ? 'width: 60%;float:right' : ''"
          :class="!$vuetify.breakpoint.mobile ? 'mx-auto pl-10' : 'text-center'"
        >
          <p class="display-2 font-weight-medium" style="color: #2250b3">
            FORMAS ACTUALES DE CONTACTO
          </p>
          <hr
            :class="!$vuetify.breakpoint.mobile ? '' : 'mx-auto'"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'width: 35%; border: solid 2px; color: #fec12d'
                : 'width: 60%; border: solid 2px; color: #fec12d'
            "
          />
        </div>

        <div
          :class="
            !$vuetify.breakpoint.mobile
              ? 'mx-auto mt-10 pl-11'
              : 'text-center mt-10'
          "
          :style="!$vuetify.breakpoint.mobile ? 'width: 60%; float: right' : ''"
        >
          <p style="color: #2250b3">
            Tomamos la inmediatez y la atención personalizada de una llamada
            telefónica y la combinamos con la facilidad e instantaneidad de
            Internet. Además, bajamos el costo del número 800.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div>
          <v-img
            eager
            class="mx-auto ml-1"
            :src="require('../../assets/LineaClick.png')"
            :width="!$vuetify.breakpoint.mobile ? '50%' : '95%'"
          />
        </div>
      </v-col>
    </v-row>
    <v-container
      fluid
      :style="!$vuetify.breakpoint.mobile ? '' : 'background: #fec12d;'"
      :class="!$vuetify.breakpoint.mobile ? 'probando FondoWoman mb-16' : ''"
    >
      <div :style="!$vuetify.breakpoint.mobile ? 'transform: skewY(5deg)' : ''">
        <v-img
          class="mb-n3"
          eager
          :src="require('../../assets/callwoman.png')"
          :style="
            !$vuetify.breakpoint.mobile
              ? ' margin-top:-2%;top:0left: 0;right:;max-width: 40%;transform: skewY(-12deg)'
              : 'margin-top:-12%'
          "
        />
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "FormasContacto",
};
</script>

<style>
.probando {
  background: #fec12d;
  transform: skewY(5deg);
}
.FondoWoman {
  background-image: url("../../assets/FondoCallWoman.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
}
</style>