import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/Index.vue'
import Panel from '../Panel/Panel.vue'
import SocketVideoPanel from '../Panel/SocketVideoPanel.vue'
import Registrar from '../Registrar/Registrar.vue'
import SalesForm from '@/components/Forms/SalesForm'
import ProspectAccept from '@/components/Forms/ProspectAccept'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Panel',
    name: 'panel',
    component: Panel
  },
  {
    path: '/SocketVideoPanel',
    name: 'SocketVideoPanel',
    component: SocketVideoPanel
  },
  {
    path: '/Registrar',
    name: 'Registrar',
    component: Registrar
  },
  {
    path: '/sales-form',
    name: 'SalesForm',
    component: SalesForm
  },
  {
    path: '/sales-confirm',
    name: 'ProspectAccept',
    component: ProspectAccept
  },

]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
