
<template>
  <div class="FondoHome">
		<div class="video-container"
				v-show="tab === 4">
			<div class="video-over-container d-flex flex-row justify-center align-center">
			</div>
			<video-player
				class="home-video-background-top"
				:class="$vuetify.breakpoint.mobile ? 'mt-4' : ''"
				ref="videoPlayer"
				:playsinline="true"
				:options="playerOptions"
				@ended="onStop"
        
				:style="
					$vuetify.breakpoint.mobile
						? 'width: 100%;z-index: 9'
						: 'width: 610px;z-index: 9'
				"
			>
			</video-player>
		</div>
    <v-container>
      <v-row>
        <v-col class="d-flex align-center justify-center" cols="12" md="4">
          <div>
            <v-img
              :src="require('../../assets/MinClick2.png')"
              eager
              max-width="200"
            />
          </div>
        </v-col>
        <v-col class="d-flex align-center justify-center" cols="12" md="8">
          <v-row justify="center">
            <v-col>
              <v-btn color="#2250B3" text>Marketplace</v-btn>
            </v-col>
            <v-col>
              <v-btn
                :class="!$vuetify.breakpoint.mobile ? '' : ''"
                color="#2250B3"
                text
                >Servicios</v-btn
              >
            </v-col>
            <v-col>
              <v-btn color="#2250B3" text>Precios</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#2250B3" text>Contactos</v-btn>
            </v-col>
            <v-col>
              <v-btn
                href="https://panel.click2call.cl"
								target="_blank"
                :style="
                  !$vuetify.breakpoint.mobile
                    ? 'background: white'
                    : 'background: white;'
                "
                color="#2250B3"
                text
                class="rounded-lg"
                >Ingresar</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-container class="mt-16">
        <v-row>
          <v-col cols="12">
            <v-container>
            <v-tabs-items
              :style="
                $vuetify.breakpoint.mobile
                  ? 'background-color: transparent !important'
                  : 'background-color: transparent !important;height:350px'
              "
              v-model="tab"
            >
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="d-flex flex-row">
                        <v-icon
                          :class="
                            $vuetify.breakpoint.mobile ? 'd-none' : 'mr-3 mt-n4'
                          "
                          color="white"
                          size="40"
                          >mdi-poll</v-icon
                        >
                        <p
                          style="color: #2250b3"
                          class="display-2 font-weight-bold"
                        >
                          C2C EN TU WEB.
                        </p>
                      </div>
                      <div>
                        <p style="color: #2250b3" class="display-1">
                          Agrega
                          <span class="font-weight-bold">“Click2Call”</span> a
                          tu WEB y únete a nuestro revolucionario C2C.
                        </p>
                        <p style="color: #2250b3" class="body-1 text-justify">
                          Incorporando
                          <span class="font-weight-bold">“Click2Call”</span> en
                          tu WEB, tus Clientes se podrán comunicar
                          instantáneamente ya sea por Voz, Video llamada o
                          VideoMail con tu Call Center o departamento de Ventas
                          de tu empresa, con sólo hacer un click de mouse sobre
                          la alternativa de comunicación que te ofrece nuestro
                          Sistema
                          <span class="font-weight-bold">“Click2Call”</span>.
                        </p>
                      </div>
                      <div>
                        <v-btn
                          color="#2250b3"
                          style="color: white"
                          elevation="0"
                          width="240"
                          height="50"
                          class="rounded-lg text-capitalize title"
                          >Agregar botón</v-btn
                        >
                      </div>
                    </v-col>

                    <v-col md="4" cols="12">
                      <div class="d-flex justify-center">
                        <v-img max-width="250" eager :src="require('../../assets/AgregaTuWeb.svg')"/>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="d-flex flex-row">
                        <v-icon
                          :class="
                            $vuetify.breakpoint.mobile ? 'd-none' : 'mr-3 mt-n4'
                          "
                          color="white"
                          size="40"
                          >mdi-phone-forward-outline</v-icon
                        >
                        <p
                          style="color: #2250b3"
                          class="display-2 font-weight-bold"
                        >
                          Llamada Telefónica
                        </p>
                      </div>
                      <div>
                        <p style="color: #2250b3" class="display-1">
                          Llamada por Voz a un Ejecutivo desde
                          <span class="font-weight-bold">“Click2Call”</span>.
                        </p>
                        <p style="color: #2250b3" class="body-1 text-justify">
                          Con
                          <span class="font-weight-bold">“Click2Call”</span> en
                          tu WEB, tus Clientes se podrán comunicar directamente
                          por Audio con tus Vendedores o Ejecutivos de Atención,
                          solo dando un click en la opción "Llamada Telefónica".
                        </p>
                      </div></v-col
                    >
                    <v-col md="4" cols="12">
                      <div class="d-flex justify-center">
                        <v-img max-width="300" eager :src="require('../../assets/LlamadaTelefonica.png')"/>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col md="6" cols="12">
                      <div class="d-flex flex-row">
                        <v-icon
                          :class="
                            $vuetify.breakpoint.mobile ? 'd-none' : 'mr-3 mt-n4'
                          "
                          color="white"
                          size="40"
                          >mdi-video</v-icon
                        >
                        <p
                          style="color: #2250b3"
                          class="display-2 font-weight-bold"
                        >
                          VIDEO LLAMADA.
                        </p>
                      </div>
                      <div>
                        <p
                          style="color: #2250b3"
                          class="display-1 font-weight-light"
                        >
                          Ver a un Ejecutivo, brinda la confianza extra para
                          cerrar una Venta o entregar un Servicio de calidad
                        </p>
                        <p style="color: #2250b3" class="body-1 text-justify">
                          <span class="font-weight-bold">“Click2Call”</span>
                          permite que tus Clientes se puedan contactar
                          instantáneamente con tus Ejecutivos o representantes
                          de Ventas, por medio de una
                          <span class="font-weight-bold">“Video llamada”</span>,
                          esto permite dar un mayor grado de confianza en las
                          atenciones que tu empresa entrega.
                        </p>
                      </div>
                    </v-col>
                    <v-col md="4" cols="12">
                      <div
                        :class="
                          $vuetify.breakpoint.mobile
                            ? 'd-flex justify-center align-center'
                            : ''
                        "
                      >
                        <v-img
                          max-width="230"
                          eager
                          :src="require('../../assets/Cuadro.png')"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <div class="d-flex flex-row">
                        <v-icon
                          :class="
                            $vuetify.breakpoint.mobile ? 'd-none' : 'mr-3 mt-n4'
                          "
                          color="white"
                          size="40"
                          >mdi-email-outline</v-icon
                        >
                        <p
                          style="color: #2250b3"
                          class="display-2 font-weight-bold"
                        >
                          VIDEO MAIL
                        </p>
                      </div>
                      <div>
                        <p
                          style="color: #2250b3"
                          class="display-1 font-weight-light"
                        >
                          Una herramienta ideal para que tus Clientes no se
                          sientan ignorados.
                        </p>
                        <p style="color: #2250b3" class="body-1 text-justify">
                          Cuando tienes
                          <span class="font-weight-bold">“Click2Call”</span> en
                          tu WEB, no perderás Clientes por no poder contestar
                          una llamada, esto podrá ser una realidad con nuestro
                          exclusivo envío de
                          <span class="font-weight-bold">“VideoMail”</span>, así
                          tus Clientes podrán dejar un mensaje por video,
                          detallando la consulta respecto de un producto, una
                          venta o servicio que tu sitio WEB entrega.
                        </p>
                      </div>
                    </v-col>
                    <v-col md="4" cols="12">
                      <div class="d-flex justify-center">
                        <v-img max-width="300" eager :src="require('../../assets/VideoMail.png')"/>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item >
                <v-container class="d-flex justify-center align-center"  style="height: 100%;margin-top:7%" h-100>
									<v-btn
										elevation="6"
										height="50"
										width="250"
										class="rounded-xl"
										style="color: #2250b3"
										@click="playVideo"
										v-show="showPlayBtn"
										><p class="mt-4 subtitle-2 font-weight-medium">
											Ver Video
										</p>
									</v-btn>
                </v-container>
              </v-tab-item>
            </v-tabs-items>

            </v-container>
          </v-col>
        </v-row>

        <v-divider class="mt-10"></v-divider>
        <v-tabs show-arrows background-color="transparent" v-model="tab">
          <v-tabs-slider color="#2250B3" />
          <v-tab
            class="font-weight-bold"
            style="color: #2250b3"
            v-for="(item, index) in items"
            :key="index"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-container>
    </v-container>

    
  </div>
</template>

<script>
import Drawer from "../../drawer/Drawer.vue";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";

export default {
  name: "Home",
  components: {
    Drawer,
    videoPlayer,
  },
  data() {
    return {
      tab: null,
			showPlayBtn: true,
      items: [
        "Agrega a tu web",
        "Llamada telefónica",
        "Video llamada",
        "video mail",
        "video del sistema",
      ],
      prueba: [],
      playerOptions: {
        // videojs options
        autoplay: false,
        muted: false,
        // aspectRatio: Math.round(this.dialogWidth) + ":" + Math.round(this.dialogHeight),
        language: "es",
        sources: [
          {
            type: "video/mp4",
            src: "/click2call.mp4",
          },
        ],
      },
    };
  },
	methods: {
		playVideo(){
			this.$refs.videoPlayer.player.play()
			this.showPlayBtn = false
		},
		onStop(){
			this.showPlayBtn = true
		}
	}
};
</script>

<style scoped>
@media (min-width: 1904px){
  .container {
      max-width: 1485px;
  }
}
.FondoHome {
  background-image: url("../../assets/FondoMain.svg");
  background-color: #fec12d;
  background-position: center;
  background-size: cover;
  height: auto;
	position: relative;
}
.video-container{
	position: absolute;
	width: 100%!important;
	z-index: 0!important;
	height: 100%;
}
.home-video-background-top{
	width: 100%!important;
	height: 100%;
}
.video-over-container{
	width: 100%;
	height: 100%;
	/* background-color: rgba(254,191,45,0.6); */
	position: absolute;
	z-index: 1;
}
/deep/ .home-video-background-top > .video-js{
	width: 100%;
	height: 100%;
}
/deep/ .home-video-background-top > .video-js > video{
	object-fit: cover;
}
</style>
