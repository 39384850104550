<template>
  <v-container
    class="fondoLogin d-flex align-center justify-center"
    style="height: 100%"
    fluid
  >
			<v-img class="img-top-background" eager :src="require('@/assets/Fondo.svg')" />
			<v-slide-x-reverse-transition>
			<v-alert v-if="alert" 
				class="alert-message-container"
				dense
				:type="alert.type" >
				<p class="mb-0 body-2">{{alert.message}}</p>
			</v-alert>
			</v-slide-x-reverse-transition>
			<v-card class="rounded-xl" min-height="350px"
				:class="$vuetify.breakpoint.mobile ? 'pa-2':'pa-10'"
				:min-width="$vuetify.breakpoint.mobile ? '100px':'700px'" >
				<div class="pb-5 d-flex justify-center">
					<v-img
						max-width="250"
						eager
						:src="require('../../assets/Click2Call.png')"
					/>
				</div>
				<div v-if="loadingCampaign">
						<p class="text-center" style="color:#2250B3">Estamos cargando la información</p>
					<v-skeleton-loader
						type="list-item-three-line"
					></v-skeleton-loader>
				</div>
				<div v-else>
					<div v-if="confirmation_status">
						<h2 class="text-center" style="color:#2250B3">Formulario Enviado</h2>
						<p class="mb-0 text-center" style="color:#2250B3">{{campaignData.first_step.output_form_description}}</p>
						<div v-html="campaignData.first_step.output_form_text"></div>
						<v-img
							max-width="350"
							height="250"
							contain
							class="mx-auto"
							eager
							v-if="campaignData.first_step.output_form_image"
							:src="campaignData.first_step.output_form_image"
						/>
					</div>
					<div v-else>
						<div v-if="campaignData">
							<!-- <p class="mb-0 text-center" style="color:#2250B3">Agradecemos tu interés en <b>{{campaignData.name}}</b></p> -->
							<p class="mb-0 text-center" style="color:#2250B3">{{campaignData.first_step.entry_form_description}}</p>
							<div v-html="campaignData.first_step.entry_form_text"></div>
						<v-img
							max-width="350"
							height="250"
							contain
							class="mx-auto"
							eager
							v-if="campaignData.first_step.entry_form_image"
							:src="campaignData.first_step.entry_form_image"
						/>
						</div>
						<div v-else>
							<p class="mb-0 text-center" style="color:#2250B3">Agradecemos tu interés en nuestras ofertas, por favor ingresa tus datos para comunicarnos contigo a la brevedad</p>
						</div>

						<v-container class="mt-4">
							<v-form
								ref="form"
								v-model="valid"
								lazy-validation
								class="mx-auto"
								style="max-width: 400px"
							>
								<v-text-field
									background-color="white"
									outlined
									v-model="name"
									label="Nombre"
									:rules="requiredRule"
									color="#022D41"
									hide-details
									class="my-2"
									dense
								>
									<template v-slot:prepend-inner>
										<v-icon color="#2250B3"
											>mdi-account</v-icon
										>
									</template>
								</v-text-field>
								<v-text-field
									background-color="white"
									outlined
									v-model="email"
									label="Correo"
									:rules="emailRules"
									color="#022D41"
									hide-details
									class="my-2"
									dense
								>
									<template v-slot:prepend-inner>
										<v-icon color="#2250B3"
											>mdi-email</v-icon
										>
									</template>
								</v-text-field>
								<v-text-field
									background-color="white"
									outlined
									v-model="phone"
									label="Teléfono"
									:rules="requiredRule"
									color="#022D41"
									hide-details
									class="my-2"
									dense
								>
									<template v-slot:prepend-inner>
										<v-icon color="#2250B3"
											>mdi-phone</v-icon
										>
									</template>
								</v-text-field>

								<v-tab class="mt-10 pb-10 mx-auto">
									<v-btn
										class="rounded-lg"
										color="#2250B3"
										dark
										block
										depressed
										:loading="loading"
										@click="sendData"
									>
										Enviar Información
									</v-btn>
								</v-tab>
							</v-form>
						</v-container>
					</div>
				</div>
			</v-card>
  </v-container>
</template>

<script>
import {getCampaign, createProspect} from '@/helpers/api/sales'

export default {
  name: "SalesForm",
  data: () => ({
		alert: null,
		loading: false,
		confirmation_status: false,
		name: '',
		email: '',
		phone: '',
    valid: true,
		campaign_id: null,
		campaignData: null,
		loadingCampaign: true,
    requiredRule: [
      (v) => !!v || "Este campo es obligatorio"
    ],
    emailRules: [
      (v) => !!v || "Este campo es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
  }),
	mounted(){
		this.campaign_id = this.$route.query.cp
		this.getCampaignData()
	},
  methods: {
		async getCampaignData(){
			if(this.campaign_id){
				const data = {
					campaign_id: this.campaign_id
				}
				this.loadingCampaign = true
				const campaign = await getCampaign(data)
				this.campaignData = campaign.data
			}
			this.loadingCampaign = false
		},
		async sendData(){
      if (this.$refs.form.validate()) {
				const data = {
					sales_campaign_id: this.campaign_id,
					step_id: this.campaignData.first_step.id,
					name: this.name,
					email: this.email,
					phone_number: this.phone
				}
				this.loading = true
				const create = await createProspect(data)
				this.loading = false
				if(create.success){
					this.confirmation_status = true
					this.name = ''
					this.email = ''
					this.phone = ''
				}
				else{
					this.alert = {
						type: 'warning',
						message: 'Ha ocurrido un error al enviar el formulario, por favor intenta nuevamente'
					}
				}
			}
		}
  },
};
</script>

<style scoped>
.fondoLogin {
  background: linear-gradient(#ffd255,#ffd255);
  background-position: center;
  background-size: cover;
  height: auto;
}
.sol{
  position: absolute;
  overflow: hidden;
  z-index: 1;
  bottom: 0;
  left: 0;
}
.img-top-background{
  position: absolute;
  overflow: hidden;
	top: 0;
	right: 0;
  bottom: 0;
  left: 0;
}
.alert-message-container{
	position: absolute;
  z-index: 99;
	top: 50px;
	right: 10px;
}
</style>