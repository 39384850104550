<template>
  <v-container
    class="FondoFoter FontAvenir"
    fluid
    style="position: relative; margin-top: -4%"
  >
    <v-container>
      <v-row justify="center" class="mt-16">
        <v-col cols="12" sm="6">
          <div style="color: white" class="mx-auto ">
            <p class="display-1">
              NUESTRA HERRAMIENTA LE PERMITIRÁ A LA EMPRESA:
            </p>
            <p class="mt-16">1.Mejorar Su Atención Al Cliente</p>
            <p class="mt-5">2.Fidelizar A Sus Clientes</p>
            <p class="mt-5">3.Evitar El Éxodo De Su Web</p>
            <p class="mt-5">4.Incrementar Su Conversión O Ventas</p>
            <p class="mt-5">5.Mejorar el control de gestión de ejecutivos.</p>
            <p class="mt-5">6.Comunicación en todo momento con sus empleados.</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div style="margin-top:20%" class="text-center">
            <p style="color: white" class="title">
              Natural Phone todos los derechos reservados 2021
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "NuestraHerramienta",
};
</script>

<style>
.FondoFoter {
  background-image: url("../../assets/FondoFooter.png");
  background-size: cover;
  background-position: center;
  height: auto;
}
</style>