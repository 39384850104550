import { render, staticRenderFns } from "./NuevoSocketVideo.vue?vue&type=template&id=537a8c40&scoped=true&"
import script from "./NuevoSocketVideo.vue?vue&type=script&lang=js&"
export * from "./NuevoSocketVideo.vue?vue&type=script&lang=js&"
import style0 from "./NuevoSocketVideo.vue?vue&type=style&index=0&id=537a8c40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537a8c40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCol,VContainer,VIcon,VProgressCircular,VRow,VScrollYReverseTransition,VTooltip})
