const endpoints ={
login: '/api/login',
userData: '/api/userData',
videollamada: '/api/calls/videoCall',
videoEmail: '/api/calls/videoMail',
speciality: '/api/company/specialities',
callEvent: '/api/events',
postFormContac: '/api/attention/contact',

//Registro
register: '/api/register',
getToken: 'api/token',

//SERVICIOS
getServices: '/api/company/serviceStatus',

fileUpload: 'api/calls/videoCall/{videoCallID}/publicAttachment',
getFilePublicUrl: 'api/attachments/'
}

export {endpoints}