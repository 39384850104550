import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    primaryDB: null,
    derived: null,
    step: 1,
    specialityID:null,
    modalButton:1,
		modalVideoState: false,
    roomId: null,
  },
  mutations: {
    setRoomId(state, val){
      // console.log("setRoomId", val )
      state.roomId = val
      // this.roomId = val
      // console.log(this.roomId)
    },
    setModalButton(state, val){
      state.modalButton = val
      // console.log("estado modal drawer:",val)
    },
    setPrimaryDB (state,value) {
      state.primaryDB = value
		},
		setDerived (state, val){
			state.derived = val
			// console.log("val",val);
		},
		setStep (state,val){
			state.step = val
			// console.log("step funciono",val);
		},
		setSpeciality (state,val){
			state.specialityID = val
			console.log("Speciality ID",val);
		},
		setModalVideoState (state, modalData) {
			state.modalVideoState = modalData
		},
  },
  actions: {
  },
  modules: {
  }
})
