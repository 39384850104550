<template>
  <v-app>
    <!-- <Home/> -->
    <Videos v-if="$store.state.modalVideoState" />
    <router-view />
  </v-app>
</template>

<script>
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Videos from "@/components/Modals/Videos";

export default {
  name: "App",
  components: { Videos },
  data() {
    return {};
  },
  mounted() {
    this.initFirebase();
  },
  methods: {
    initFirebase() {
      const firebaseConfig = {
        apiKey: "AIzaSyC__hpErbrF-l9vP_7mWI8FSXSjqJFAyG0",
        authDomain: "click2call-671d1.firebaseapp.com",
        databaseURL: "https://click2call-671d1-default-rtdb.firebaseio.com", //PROD
        //databaseURL: "https://click2call-dev.firebaseio.com", //DEV
        projectId: "click2call-671d1",
        storageBucket: "click2call-671d1.appspot.com",
        messagingSenderId: "360045088915",
        appId: "1:360045088915:web:aa0f190408fd668efcfd66",
        measurementId: "G-J9DTZPLG1S",
      };

      //   const firebaseConfig = {
      //   apiKey: "AIzaSyD4yWqis7Qny7gfp5bugquN1o1c1ZCzxBU",
      //   authDomain: "click2call-dev-d9306.firebaseapp.com",
      //   databaseURL: "https://click2call-dev-d9306-default-rtdb.firebaseio.com",
      //   projectId: "click2call-dev-d9306",
      //   storageBucket: "click2call-dev-d9306.appspot.com",
      //   messagingSenderId: "643543297069",
      //   appId: "1:643543297069:web:1edbbfab15cf737cf51ca4",
      //   measurementId: "G-YPR9NQ3VW4"
      // };

      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Avenir";
  src: local("Avenir"),
    url(./assets/Font/Avenir-Font/avenir_ff/AvenirLTStd-Roman.otf)
      format("truetype");
}
.FontAvenir {
  font-family: "Avenir";
  font-size: 20px;
}
</style>
