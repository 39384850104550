import request from '@/helpers/request'
import {endpoints} from './endpoints'

export function getCampaign (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCampaign,
			method: 'GET',
			params: data
		})
		.then(async function(response){
			resolve(response)
		})
		.catch(function (error){
			reject(error)
		})
	})
}
export function createProspect (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.create,
			method: 'POST',
			data
		})
		.then(async function(response){
			resolve(response)
		})
		.catch(function (error){
			reject(error)
		})
	})
}
export function confirmProspect (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.confirmProspect,
			method: 'POST',
			data
		})
		.then(async function(response){
			resolve(response)
		})
		.catch(function (error){
			reject(error)
		})
	})
}
export function getCurrentStep (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCurrentStep,
			method: 'GET',
			params: data
		})
		.then(async function(response){
			resolve(response)
		})
		.catch(function (error){
			reject(error)
		})
	})
}