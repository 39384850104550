<template>
  <v-container  fluid style="max-width: 300px;height:100vh; border-right: 1px solid #bbbbbb;background:white">
    <v-navigation-drawer floating permanent max-width="300" style="background-color: transparent;">
      <v-list-item>
        <v-row class="mb-5 mt-5">
          <v-col>
            <div
              class="rounded-lg mx-auto"
              style="background: #fec12d; width: 230px"
            >
              <v-img
                class="mx-auto"
                eager
                width="200"
                :src="require('../assets/Click2CallM.svg')"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-list-item>

      <v-divider></v-divider>

      <v-list class="text-center">
        <v-btn
          color="white"
          block
          large
          class="my-3 px-3 d-flex flex-row justify-start align-center"
          v-for="item in items"
          :key="item.title"
          @click="goTo(item)"
        >
          <v-icon color="#2250b3" class="mr-2" v-if="item.icon">{{
            item.icon
          }}</v-icon>
          <v-img
            v-else-if="item.img"
            :src="item.img"
            width="24"
            max-width="24"
            height="24"
            contain
            class="mr-2"
          />
          <p class="mb-0 subtitle-1 text-capitalize text--secondary">
            {{ item.title }}
          </p>
        </v-btn>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "VideoCall",
          icon: "mdi-view-dashboard",
          // route: "SocketVideoPanel",
        },
      ],
      right: null,
    };
  },
  methods: {
    goTo(item) {
      if (item.route) {
        this.$router.push({ name: item.route });
      } else if (item.external) {
        window.open(item.external, "_blank");
      }
    },
  },
};
</script>

<style>
</style>