var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"FondoContacto FontAvenir",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-16"},[_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('div',{staticClass:"text-center",staticStyle:{"color":"white"}},[_c('p',{staticClass:"display-2 mx-auto",style:(!_vm.$vuetify.breakpoint.mobile ? 'width: 40%' : '')},[_vm._v(" FORMAS ACTUALES DE CONTACTO ")]),_c('hr',{staticClass:"mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'width: 10%; border: solid 2px; color: #fec12d'
              : 'width: 60%; border: solid 2px; color: #fec12d')})])])],1),_c('v-container',{staticClass:"mt-16 mb-16"},[_c('v-row',[_c('v-col',{style:(!_vm.$vuetify.breakpoint.mobile ? 'border-right: 1px solid #c1c1c1' : ''),attrs:{"cols":"12","sm":"12","md":"3"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"100px"},attrs:{"color":"white"}},[_vm._v("mdi-email-outline")])],1),_c('p',{staticClass:"text-center mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'color: white; width: 55%'
              : 'color:white')},[_vm._v(" Los clientes o prospectos NO desean enviar correos a las Empresas ya que necesitan soluciones rápidas y no días de espera. ")]),_c('hr',{staticClass:"mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'display:none'
              : 'width: 80%; border: solid 1px; color: #c1c1c1')})]),_c('v-col',{style:(!_vm.$vuetify.breakpoint.mobile ? 'border-right: 1px solid #c1c1c1' : ''),attrs:{"sm":"12","md":"3","cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"100px"},attrs:{"color":"white"}},[_vm._v("mdi-file-document-outline")])],1),_c('p',{staticClass:"text-center mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'color: white; width: 55%'
              : 'color:white')},[_vm._v(" Los usuarios NO desean escribir ni mucho menos entregar datos como: Dirección, Rut o Teléfono como requisito para ser contactados… Algún día. ")]),_c('hr',{staticClass:"mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'display:none'
              : 'width: 80%; border: solid 1px; color: #c1c1c1')})]),_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"100px"},attrs:{"color":"white"}},[_vm._v("mdi-phone-in-talk")])],1),_c('p',{staticClass:"text-center mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'color: white; width: 55%'
              : 'color:white')},[_vm._v(" Los clientes NO desean anotar un teléfono y luego tener que gastar dinero en generar una llamada para que la Empresa resuelva su problema. ")]),_c('hr',{staticClass:"mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'display:none'
              : 'width: 80%; border: solid 1px; color: #c1c1c1')})]),_c('v-col',{style:(!_vm.$vuetify.breakpoint.mobile ? 'border-left: 1px solid #c1c1c1' : ''),attrs:{"cols":"12","md":"3","sm":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"100px"},attrs:{"color":"white"}},[_vm._v("mdi-robot-confused-outline")])],1),_c('p',{staticClass:"text-center mx-auto",style:(!_vm.$vuetify.breakpoint.mobile
              ? 'color: white; width: 55%'
              : 'color:white')},[_vm._v(" Atención de BOT el Usuario está cansado que no le resuelvan el problema inmediatamente ya que el BOT no tiene todas las respuestas que el Cliente necesita. ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }