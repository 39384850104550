<template>
  <v-container style="background:#2250b3" fluid class="pa-0 pb-">
    <div class="d-flex flex-row">
      <div class="d-flex flex-nowrap">
        <Drawer />
      </div>
      <v-container class="pa-0" style="width: 100%; ">
        <!-- <SocketVideoPanel />         -->
        <ConexionEntrante />
      </v-container>
    </div>
  </v-container>
</template>

<script>
// import { userData } from "../helpers/api/calls";
// import { getDatabase, ref, onValue } from "firebase/database";

import Drawer from "./Drawer.vue";
// import SocketVideoPanel from "./SocketVideoPanel.vue";
import ConexionEntrante from "./SocketVideoPanel/ConexionEntrante.vue";
export default {
  data() {
    return {
      info: [],
      alert: [],
    };
  },
  components: {
    Drawer,
    // SocketVideoPanel,
    ConexionEntrante,
  },
  mounted() {
    // this.userData();
  },
  methods: {
    // async userData() {
    //   const info = await userData();
    //   this.info = info.data;
    //   console.log(this.info);
    //   // this.ListenAlert()
    // },
    // ListenAlert() {
    //   const db = getDatabase();
    //   // const starCountRef = ref(db, 'notifications/' + this.info.company_id + '/' + this.info.id);
    //   const starCountRef = ref(db, 'notifications/' + this.info.company_id);
    //   onValue(starCountRef, (snapshot) => {
    //     const data = snapshot.val();
    //     // console.log("Porbando data", data);
    //     this.alert = data
    //     // console.log(this.alert);
    //   })
    // },
  },
};
</script>
