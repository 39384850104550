<template>
  <v-container fluid class="FondoContacto FontAvenir">
    <v-row class="mt-16">
      <v-col md="12" xs="12">
        <div class="text-center" style="color: white">
          <p
            :style="!$vuetify.breakpoint.mobile ? 'width: 40%' : ''"
            class="display-2 mx-auto"
          >
            FORMAS ACTUALES DE CONTACTO
          </p>
          <hr
            class="mx-auto"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'width: 10%; border: solid 2px; color: #fec12d'
                : 'width: 60%; border: solid 2px; color: #fec12d'
            "
          />
        </div>
      </v-col>
    </v-row>
    <v-container class="mt-16 mb-16">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="3"
          :style="
            !$vuetify.breakpoint.mobile ? 'border-right: 1px solid #c1c1c1' : ''
          "
        >
          <div class="text-center">
            <v-icon color="white" style="font-size: 100px"
              >mdi-email-outline</v-icon
            >
          </div>
          <p
            :style="
              !$vuetify.breakpoint.mobile
                ? 'color: white; width: 55%'
                : 'color:white'
            "
            class="text-center mx-auto"
          >
            Los clientes o prospectos NO desean enviar correos a las Empresas ya
            que necesitan soluciones rápidas y no días de espera.
          </p>
          <hr
            class="mx-auto"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'display:none'
                : 'width: 80%; border: solid 1px; color: #c1c1c1'
            "
          />
        </v-col>

        <v-col
          sm="12"
          md="3"
          cols="12"
          :style="
            !$vuetify.breakpoint.mobile ? 'border-right: 1px solid #c1c1c1' : ''
          "
        >
          <div class="text-center">
            <v-icon color="white" style="font-size: 100px"
              >mdi-file-document-outline</v-icon
            >
          </div>
          <p
            :style="
              !$vuetify.breakpoint.mobile
                ? 'color: white; width: 55%'
                : 'color:white'
            "
            class="text-center mx-auto"
          >
            Los usuarios NO desean escribir ni mucho menos entregar datos como:
            Dirección, Rut o Teléfono como requisito para ser contactados… Algún
            día.
          </p>
          <hr
            class="mx-auto"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'display:none'
                : 'width: 80%; border: solid 1px; color: #c1c1c1'
            "
          />
        </v-col>
        <v-col md="3" sm="12" cols="12">
          <div class="text-center">
            <v-icon color="white" style="font-size: 100px"
              >mdi-phone-in-talk</v-icon
            >
          </div>
          <p
            :style="
              !$vuetify.breakpoint.mobile
                ? 'color: white; width: 55%'
                : 'color:white'
            "
            class="text-center mx-auto"
          >
            Los clientes NO desean anotar un teléfono y luego tener que gastar
            dinero en generar una llamada para que la Empresa resuelva su
            problema.
          </p>
          <hr
            class="mx-auto"
            :style="
              !$vuetify.breakpoint.mobile
                ? 'display:none'
                : 'width: 80%; border: solid 1px; color: #c1c1c1'
            "
          />          
        </v-col>

        <v-col
          cols="12"
          md="3"
          sm="12"
          :style="
            !$vuetify.breakpoint.mobile ? 'border-left: 1px solid #c1c1c1' : ''
          "
        >
          <div class="text-center">
            <v-icon color="white" style="font-size: 100px"
              >mdi-robot-confused-outline</v-icon
            >
          </div>
          <p
            :style="
              !$vuetify.breakpoint.mobile
                ? 'color: white; width: 55%'
                : 'color:white'
            "
            class="text-center mx-auto"
          >
            Atención de BOT el Usuario está cansado que no le resuelvan el
            problema inmediatamente ya que el BOT no tiene todas las respuestas
            que el Cliente necesita.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Contacto",
};
</script>

<style>
.FondoContacto {
  background-image: url("../../assets/FondoContacto.svg");
  background-color: #012145;
  background-position: center;
  background-size: cover;
  height: auto;
}
</style>